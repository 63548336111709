import React from 'react';
import { Card, CardBody, CardTitle, Col } from 'reactstrap';
import { MonthlyRevenueChart } from './StatCharts';

const MonthlyRevenue = ({ data }) => {
    return (
        <React.Fragment>
            <Col lg={8}>
                <Card>
                    <CardBody>
                    <button type="button" className="btn btn-small-round btn-outline-primary" style={{position: 'relative', float: 'right'}} onClick={() => window.location.href = '/fundraising/transactions'}>Transactions <i className='bx bx-chevron-right'></i></button>
                        <div className="d-sm-flex flex-wrap">
                            <CardTitle tag="h4" className="mb-4">Monthly Revenue</CardTitle>
                        </div>
                        <div className="d-sm-flex flex-wrap">
                            <p style={{fontSize: '12px', fontWeight: '400'}}>This chart displays the most recent financial information for your organization.</p>
                        </div>
                        <MonthlyRevenueChart dataColors='["--bs-primary", "--bs-success", "--bs-warning", "--bs-info"]' revenue={data}  dir="ltr" />
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default MonthlyRevenue;