import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, CardBody } from 'reactstrap';

//import images
import blankAvatar from "../../assets/images/avatar.jpeg";

const Section = ({user}) => {
    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col lg={12}>
                    <Card>
                        <CardBody>

                            <div className="d-flex align-items-center">
                                <img src={blankAvatar} alt="" className="avatar-sm rounded" />
                                <div className="ms-3 flex-grow-1">
                                    <h5 className="mb-2 card-title">{user?.first_name} {user?.last_name}</h5>
                                    <p className="text-muted mb-0">{user?.email}</p>
                                </div>
                                <div>
                                    <Link to="#" className="btn btn-primary"><i className="bx bx-edit align-middle"></i> Edit Profile</Link>
                                </div>
                            </div>

                        </CardBody>
                    </Card>
                    
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Section;