import React, { useEffect, useRef, useCallback } from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import tobi from "../../assets/images/tobi.png";

const SidebarContent = (props: any) => {
  console.log(props);
  const ref = useRef<any>();
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/dashboard" >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="#" className="has-arrow">
                <i className="bx bx-buildings"></i>
                <span style={{fontWeight: '800'}}>{props.t("Organizations")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/organizations">{props.t("All Organizations")}</Link>
                </li>
                <li>
                  <Link to="/organizations/documents">{props.t("Documents")}</Link>
                </li>
                <li>
                  <Link to="/organizations/provision">{props.t("Provision")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#" className="has-arrow">
                <i className="bx bx-user"></i>
                <span style={{fontWeight: '800'}}>{props.t("People")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/people/users">{props.t("Users")}</Link>
                </li>
                <li>
                  <Link to="/people/deactivations">{props.t("Deactivations")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#" className="has-arrow">
                <i className="bx bx-money"></i>
                <span style={{fontWeight: '800'}}>{props.t("Finance")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/finance/billing">{props.t("Billing")}</Link>
                </li>
                <li>
                  <Link to="/finance/transactions">{props.t("Transactions")}</Link>
                </li>
                <li>
                  <Link to="/finance/campaigns">{props.t("Campaigns")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#" className="has-arrow">
                <i className="bx bx-spreadsheet"></i>
                <span style={{fontWeight: '800'}}>{props.t("Compliance")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/compliance/identities">{props.t("Pending Identities")}</Link>
                </li>
                <li>
                  <Link to="/compliance/documents">{props.t("Pending Documents")}</Link>
                </li>
                <li>
                  <Link to="/compliance/auditors">{props.t("Auditors")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#" className="has-arrow">
                <i className="bx bx-task"></i>
                <span style={{fontWeight: '800'}}>{props.t("Onboarding")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/onboarding/formation">{props.t("Formation Steps")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#" className="has-arrow">
                <i className="bx bx-text"></i>
                <span style={{fontWeight: '800'}}>{props.t("Marketing")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/onboarding/formation">{props.t("Blog Posts")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#" className="has-arrow">
                <i className="bx bx-bullseye"></i>
                <span style={{fontWeight: '800'}}>{props.t("Sales")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/sales/demos">{props.t("Demo Requests")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="#" className="has-arrow">
                <img src={tobi} alt="Tobi" height="30" style={{marginRight: '20px'}} />
                <span style={{fontWeight: '800'}}>{props.t("Tobi")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tobi/history">{props.t("Prompt History")}</Link>
                </li>
                <li>
                  <Link to="/tobi/errors">{props.t("Error Log")}</Link>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));
