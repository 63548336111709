import { getFirebaseBackend } from "helpers/firebase_helper";
import { postLogin } from "helpers/backend_helper";
import { setAuthorization } from "helpers/api_helper";
import { loginSuccess, apiError, logoutUserSuccess, resetLoginFlag } from "./reducer";
import { getProfile } from "../profile/thunk";



export const loginuser = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response: any;
       
        response = await postLogin({
            email: user.email,
            password: user.password
        })
    
        localStorage.setItem("token", response.token);
        setAuthorization(response.token);
        dispatch(loginSuccess(response));
        dispatch(getProfile());
        history('/dashboard');
    } catch (error) {
        dispatch(apiError(error));
    }
}

export const logoutUser = () => async (dispatch: any) => {
    try {
        localStorage.removeItem("token");
        localStorage.removeItem("redux");
        dispatch(logoutUserSuccess(true));
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const resetLoginMsgFlag = () => {
    try {
        const response = resetLoginFlag();
        return response;
    } catch (error) {
        return error;
    }
};


export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
    try {
        let response: any;

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend();
            response = fireBaseBackend.socialLoginUser(type);
        }

        const socialdata = await response;
        if (socialdata) {
            sessionStorage.setItem("authUser", JSON.stringify(socialdata));
            dispatch(loginSuccess(socialdata));
            history('/dashboard');
        }

    } catch (error) {
        dispatch(apiError(error));
    }
};