import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

//import components
import { StatWidgetCharts } from './StatCharts';
import { formatter as currency } from '../../helpers/format_helper';

const ChartSection = ({ chartsData }:any) => {
    return (
        <React.Fragment>
            <Row>
                {chartsData?.map((stat) => (
                    <Col lg={3} key={stat?.label}>
                        <Card className="mini-stats-wid">
                            <CardBody>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <p className="text-muted fw-medium">{stat?.label}</p>
                                        <h4 className="mb-0">{stat?.currency ? currency.format(stat?.total / 100) : stat?.total}</h4>
                                    </div>

                                    <div className="flex-shrink-0 align-self-center">
                                        <StatWidgetCharts dataColors={'["--bs-success", "--bs-transparent"]'} series={stat?.series} dir="ltr" />
                                    </div>
                                </div>
                            </CardBody>

                            <CardBody className="border-top py-3">
                                <p className="mb-0"> <span className={"badge badge-soft-success me-2"}>
                                    <i className="bx bx-trending-up align-bottom me-1"></i> {stat?.difference}%</span>
                                    Increase over last 30 days</p>

                                    {/* { stat?.difference === 0 && (<p className="mb-0" style={{fontSize: '12px', fontStyle: 'italic'}}>Not enough data for insights..</p>)} */}

                            </CardBody>

                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    );
}

export default ChartSection;