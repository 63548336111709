import React, { useState } from "react";
import { useSelector } from 'react-redux';

import { Button, Card, CardBody, Row, Col, Progress } from "reactstrap";
import tobi from "../../assets/images/tobi.png"
import tobiLoader from "../../assets/images/tobi-loader.gif"
import tobiUnsupported from "../../assets/images/error.png"
import success from "../../assets/images/success.png"
import { postTobiPrompt } from "../../helpers/backend_helper";
import { formatter } from "../../helpers/format_helper";

const Tobi = (admin) => {
  const [tobiPrompt, setTobiPrompt] = useState<any>(false);
  const [tobiWorking, setTobiWorking] = useState<any>(false);
  const [tobiResult, setTobiResult] = useState<any>(false);
  const [tobiError, setTobiError] = useState<any>(null);
  
  const { user } = useSelector((state: any) => ({
    user: state?.Profile?.user
  }));

  async function executeTobiPrompt() {
    setTobiWorking(true);
    setTobiResult(null);
    try {
        let response;
        response = postTobiPrompt(user, {'query': tobiPrompt});
        const data = await response;
        if(data) {
            setTobiWorking(false);
            setTobiResult(data);
            console.log(data);
        }
    } catch (error) {
        console.log(error);
        setTobiWorking(false);
        setTobiError(true);
    }
  }

  return (
    <React.Fragment>
      <form className="app-search d-none d-lg-block" style={{width: '700px'}}>
            <div className="position-relative">
            <input
                type="text"
                className="form-control"
                placeholder="Type what you need and Tobi will help..."
                style={{fontSize: '20px', paddingLeft: '50px'}}
                onChange={(e) => setTobiPrompt(e.target.value)}
                disabled={tobiWorking}
            />

            {tobiPrompt && (
                <Card style={{position: 'absolute', width: '700px'}}>
                    <CardBody>
                        <Row>
                            <Col xl={1}>
                                <img src={tobiWorking ? tobiLoader : tobi} alt="Tobi" height="60" />
                            </Col>
                            <Col xl={11}>
                                <p style={{fontSize: '22px'}}>{ tobiPrompt }</p>
                            </Col>

                            <Row style={{marginTop: '20px'}}>
                                {!user?.hq_admin && (<Col mg={11}><p className="text-muted font-size-10">Click the button to the right when you're ready and Tobi will work his magic. For tips and tricks, type "Teach me Tobi"!</p></Col>)}
                                <Col mg={1}><Button disabled={tobiWorking} type="button" onClick={executeTobiPrompt} color="primary" className="btn-block btn-md btn-rounded" style={{position: "relative", float: "right"}}>Work your magic, Tobi!</Button></Col>
                            </Row>

                            {user?.hq_admin && (<Col mg={11}><p className="font-size-14" style={{color: '#FF0000', marginTop: '30px'}}>NOTICE: This is an adminstrative version of the Tobi AI platform. Do not copy, modify or distribute any information provided through this interface. Always use AI responsibly.</p></Col>)}
                            
                        </Row>

                        <Row style={{marginTop: '30px'}}>
                            <Progress color="primary" value={100}></Progress>
                        </Row>

                        { tobiWorking && (<Row>
                            <img src={tobiLoader} alt="Tobi Loading" style={{width: '150px', margin: 'auto', paddingTop: '20px', animation: 'rotation 2s infinite linear'}} />
                            <p style={{fontSize: '22px', margin: 'auto', textAlign: 'center'}}>Working on it...</p>
                        </Row>)}

                        {tobiResult && (<Button disabled={tobiWorking} type="button" onClick={() => setTobiResult(null)} color="danger" className="btn-block btn-md btn-rounded" style={{position: "relative", float: "right", marginTop: '20px'}}>X</Button>)}

                        {tobiResult && tobiResult.action === 'create_campaign' && (
                           <Row style={{marginTop: '30px'}}>
                                <Col xl={1}>
                                    <img src={success} alt="Tobi Success" height="30" />
                                </Col>
                                <Col xl={11}>
                                    <p style={{fontSize: '22px'}}>Your new campaign has been created!</p>
                                    <p style={{fontSize: '18px'}}>Name: {tobiResult.object.name} <br/> Goal: {formatter.format(tobiResult.object.goal / 100)} <br/>Kiosk Code: {tobiResult.object.code}</p>
                                    <p style={{fontSize: '18px'}}><Button onClick={() => window.open(`/fundraising/campaigns/${tobiResult.object.id}`, '_self')} type="button" color="primary" className="btn-block btn-md btn-rounded">View Details</Button></p>
                                </Col>
                           </Row>
                        )}

                        {tobiResult && tobiResult?.action === 'error_not_supported' && (
                           <Row style={{marginTop: '30px'}}>
                                <Col xl={1}>
                                    <img src={tobiUnsupported} alt="Tobi Unsupported" height="30" />
                                </Col>
                                <Col xl={11}>
                                    <p style={{fontSize: '24px'}}>Uh oh, I don't know that yet.</p>
                                    <p style={{fontSize: '14px'}}>Sometimes customers want me to do things that I haven't learned yet. I'll add it to my growth plan and let you know when I'm able to accomplish this.</p>
                                </Col>
                           </Row>
                        )}

                        {tobiResult && tobiResult?.action === 'not_allowed' && (
                           <Row style={{marginTop: '30px'}}>
                                <Col xl={1}>
                                    <img src={tobiUnsupported} alt="Tobi Unsupported" height="30" />
                                </Col>
                                <Col xl={11}>
                                    <p style={{fontSize: '24px'}}>That wasn't very nice.</p>
                                    <p style={{fontSize: '14px'}}>Even though I am an AI, I still like to be treated with respect. Please refrain from using inappropriate language or asking me to do things that are inappropriate or offensive.</p>
                                </Col>
                           </Row>
                        )}

                        {tobiError && (
                           <Row style={{marginTop: '30px'}}>
                                <Col xl={1}>
                                    <img src={tobiUnsupported} alt="Tobi Unsupported" height="30" />
                                </Col>
                                <Col xl={11}>
                                    <p style={{fontSize: '24px'}}>Unknown error.</p>
                                    <p style={{fontSize: '14px'}}>Something went wrong and I was unable to fix it. I've let our engineers know and provided them details about the error. Please try again later.</p>
                                </Col>
                           </Row>
                        )}

                    </CardBody>
                </Card>)}
            <span> <img src={tobiWorking ? tobiLoader : tobi} alt="Tobi" height="30" /></span>
            </div>
        </form>
    </React.Fragment>
  );
};


export default Tobi;
