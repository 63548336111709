const changeBodyAttribute = (attribute: any, value: any) => {
    if (document.body) document.body.setAttribute(attribute, value)
    return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
const manageBodyClass = (cssClass: any, action: any) => {
    switch (action) {
        case "add":
            if (document.body) document.body.classList.add(cssClass)
            break
        case "remove":
            if (document.body) document.body.classList.remove(cssClass)
            break
        default:
            if (document.body) document.body.classList.toggle(cssClass)
            break
    }

    return true
}

const user_permission = (user:any, app_code:any, action:any) => {


    if(user?.administrator) {
        return true;
    }

    var obj;
    for (var key in user?.app_accesses) {
        if (user?.app_accesses[key].app_code===app_code) {  
            obj = user?.app_accesses[key]; 
            break;
        }
    }

    if(obj?.admin) {
        return true;
    }

    if(action === "viewer") {
        return obj?.viewer || obj?.manager;
    } else {
        return obj?.manager;
    }
}

export { changeBodyAttribute, manageBodyClass, user_permission };