import React, { useMemo } from "react";
import withRouter from "../../../../Components/Common/withRouter";

import { Button, Card, CardBody, CardTitle, } from "reactstrap";

import {
  DataString
} from "./OrganizationDetailColumns";

import TableContainer from "../../../../Components/Common/TableContainer";


const OrganizationsTable = ({ organizations }) => {

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return <DataString {...cellProps} />;
        },
      },

      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <DataString {...cellProps} />;
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <DataString {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "notifications_email",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <DataString {...cellProps} />;
        },
      },
      {
        Header: "EIN",
        accessor: "ein",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <DataString {...cellProps} />;
        },
      },
      {
        Header: "Verified",
        accessor: "verified",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <DataString {...cellProps} />;
        },
      },
      {
        Header: "Forming",
        accessor: "forming",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <DataString {...cellProps} />;
        },
      },
      {
        Header: "Onboarded",
        accessor: "onboarding_complete",
        disableFilters: true,
        Filter: false,
        filterable: false,
        Cell: (cellProps: any) => {
          return <DataString {...cellProps} />;
        },
      },
      
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Filter: false,
        Cell: (cellProps: any) => {
          return (
            <Button type="button" color="primary" className="btn-sm btn-rounded"> View Details </Button>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle tag="h4" className="mb-4">{''}</CardTitle>
          <TableContainer
            columns={columns}
            data={organizations || []}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={20}
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-light"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};


export default withRouter(OrganizationsTable);
