import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, CardBody, Col } from 'reactstrap';

import { formatter as currency } from '../../helpers/format_helper';

//import images
import tobi from "../../assets/images/tobi.png";
import blankAvatar from "../../assets/images/avatar.jpeg";

const PeopleSection = ({ people_awards }) => {
    return (
        <React.Fragment>
            <Col lg={4}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-3">People Awards</h4>
        
                            { people_awards?.top_volunteer && (<div>
                                <div><span className='badge badge-soft-success'>Top Volunteer</span></div>
                                <div key={people_awards?.top_volunteer?.id} className="bg-light p-3 d-flex mb-3 rounded" style={{cursor: 'pointer'}} onClick={() => window.location.href = `/people/${people_awards?.top_volunteer?.id}`}>
                                    <img src={blankAvatar} alt="" className="avatar-sm rounded me-3" />
                                    <div className="flex-grow-1">
                                        <h5 className="font-size-15 mb-2"><p>{ people_awards?.top_volunteer?.name }</p> </h5>
                                        <p className="mb-0"><i className="bx bx-trophy align-middle"></i> { `${people_awards?.top_volunteer?.total} hours volunteered`}</p>
                                    </div>
                                </div>
                            </div>)}

                            { !people_awards?.top_volunteer && (<div>
                                <div><span className='badge badge-soft-success'>Top Volunteer</span></div>
                                <div className="bg-light p-3 d-flex mb-3 rounded">
                                    <div className="flex-grow-1">
                                        <h5 className="font-size-15 mb-2"><p>No volunteers yet...</p> </h5>
                                        <p className="mb-0">Invite your volunteers to NonprofitsHQ to manage thier shifts, and gain insights about how they are interacting with your organization.</p>
                                    </div>
                                </div>
                            </div>)}

                            { people_awards?.top_donor && (<div>
                                <div><span className='badge badge-soft-success'>Top Donor</span></div>
                                <div key={people_awards?.top_donor?.id} className="bg-light p-3 d-flex mb-3 rounded" style={{cursor: 'pointer'}} onClick={() => window.location.href = `/people/${people_awards?.top_donor?.id}`}>
                                    <img src={blankAvatar} alt="" className="avatar-sm rounded me-3" />
                                    <div className="flex-grow-1">
                                        <h5 className="font-size-15 mb-2"><p>{ people_awards?.top_donor?.name }</p> </h5>
                                        <p className="mb-0"><i className="bx bx-trophy align-middle"></i> { `${currency.format(people_awards?.top_donor?.total / 100)} donated`}</p>
                                    </div>
                                </div>
                            </div>)}

                            { !people_awards?.top_donor && (<div>
                                <div><span className='badge badge-soft-success'>Top Donor</span></div>
                                <div className="bg-light p-3 d-flex mb-3 rounded">
                                    <div className="flex-grow-1">
                                        <h5 className="font-size-15 mb-2"><p>No donors yet...</p> </h5>
                                        <p className="mb-0">After you start recieving donations, we'll display your top donor here. Create a campaing to get started!</p>
                                    </div>
                                </div>
                            </div>)}
                            
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <Row>
                            <Col md={8}>
                                <h4 className="card-title mb-3">Learn how to use Tobi</h4>
                                <p className="text-muted">Tobi is the AI that powers NonprofitsHQ. Watch this quick video to see how you can get the most from Tobi.</p>
                                <div>
                                    <Link to="#" className="btn btn-primary btn-sm"><i className='bx bxs-graduation align-middle'></i> Launch Tutorial</Link>
                                </div>
                            </Col>
                            <Col md={4}>
                                <img src={tobi} alt="" height="130" />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default PeopleSection;