import React, { useEffect, useState } from 'react';
import { 
  Container,
  Row
} from 'reactstrap';

import PeopleSection from './PeopleSection';

//Import Components
import ChartSection from './ChartSection';
import Section from './Section';
import MonthlyRevenue from './MonthlyRevenue';
import { getDashboardData, getUserProfile } from '../../helpers/backend_helper';

const Dashboard = () => {
    document.title = "Dashboard | NonprofitsHQ"
    const [dashboardData, setDashboardData]:any = useState();
    const [user, setUser]:any = useState()


    async function getUser() {
        try {
            let response;
            response = getUserProfile();
            const data = await response;
            if(data) {
                setUser(data);
            }
        } catch (error) {
            console.log(error);
        }
      }

    async function getDashboard() {
      try {
          let response;
          response = getDashboardData();
          const data = await response;
          if(data) {
              setDashboardData(data);
          }
      } catch (error) {
          console.log(error);
      }
    }
  
    useEffect(() => {
        getDashboard();
    }, [])

    useEffect(() => {
        getUser();
    }, [])

    
    return (
        <React.Fragment>
            
                    { ["staff", "board", "owner"].includes(user?.account_type) && (
                        <div className="page-content">
                            <Container>
                                <div>

                                    <Section user={user} />

                                    <ChartSection chartsData={dashboardData?.statistics} />

                                    <Row>
                                        {/* MonthlyRevenue */}
                                        <MonthlyRevenue data={dashboardData?.monthly_revenue} />

                                        {/* PeopleSection */}
                                        <PeopleSection people_awards={dashboardData?.people_awards} />
                                    </Row>
                                </div>
                            </Container>
                        </div>)}

        </React.Fragment>
    );
}

export default Dashboard;