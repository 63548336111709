import React, { useEffect, useState }from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import OrganizationsTable from './OrganizationsTable';
import { useSelector } from 'react-redux';
import { getOrganizations } from "../../../../helpers/backend_helper"

const Organizations = () => {
  document.title = "Organizations | NonprofitsHQ Admin";

  const [organizations, setOrganizations]:any = useState([]);

  const { user } = useSelector((state: any) => ({
    user: state.Profile.user
  }));

  async function getAllOrganizations() {
    try {
        let response;
        response = getOrganizations();
        const data = await response;
        if(data) {
            setOrganizations(data);
        }
    } catch (error) {
        console.log(error);
    }
  }

  useEffect(() => {
    getAllOrganizations();
  }, [user])
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Admin" breadcrumbItem="Organizations" />

          <Row>
            <Col xl={10}>
              <OrganizationsTable organizations={organizations} />
            </Col>
            <Col xl={2}>
              <Row>
                {/* Reports Render */}
                  <Col md={12} key={"income"}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium"> Filter </p>
                            {/* <h4 className="mb-0">{ formatter.format(transactions?.balances?.available / 100) }</h4> */}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
}

export default Organizations;