import React from 'react';
import { Link } from 'react-router-dom';


const DataString = (cell:any) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : '--'}</Link>
    );
};

export {
    DataString,
};