//Include Both Helper File with needed methods
import { getUserProfile } from "../../../helpers/backend_helper";

// action
import { profileSuccess, profileError, resetProfileFlagChange } from "./reducer";


export const getProfile = () => async(dispatch: any) => {
    try {
        let response;
        response = getUserProfile();
        const data = await response;

        if(data) {
            dispatch(profileSuccess(data));
        }
    } catch (error) {
        dispatch(profileError(error));
    }
}

export const editProfile = (user: any) => async (dispatch: any) => {
    // try {
    //     let response;

    //     response = postJwtProfile(
    //         {
    //             username: user.username,
    //             idx: user.idx,
    //         }
    //     );

    //     const data = await response;

    //     if (data) {
    //         dispatch(profileSuccess(data));
    //     }

    // } catch (error) {
    //     dispatch(profileError(error));
    // }
};

export const resetProfileFlag = () => {
    try {
        const response = resetProfileFlagChange();
        return response;
    } catch (error) {
        return error;
    }
};